@keyframes fade-top {
	from {
		transform: translateY(-20px);
		opacity: 0;
	}
	to {
		transform: translateY(0px);
		opacity: 1;
	}
}

@keyframes fade-simple {
	from {
		opacity: 0;
	}
	to {
		opacity: 1;
	}
}

@keyframes fade-zoom-in {
	from {
		transform: scale(0.8);
		opacity: 0;
	}
	to {
		transform: scale(1);
		opacity: 1;
	}
}

@keyframes level-zoom-out {
	from {
		font-size: 50px;
		font-weight: bold;
		margin-top: 70px;
	}
	to {
		font-size: 13px;
		font-weight: 600;
		margin-top: 0;
	}
}

@keyframes fade-green {
	from {
		background-color: #5fd253;
	}
	to {
		background-color: #ececec;
	}
}

@keyframes fade-red {
	from {
		background-color: red;
	}
	to {
		background-color: #ececec;
	}
}

#header {
	animation: fade-top 1s;
}

#home-page {
	animation: fade-zoom-in 1s;
}

#difficulty-page {
	animation: fade-zoom-in 1s;
}

#play-page {
	animation: fade-zoom-in 1s;
}

#gameover-page {
	animation: fade-zoom-in 1s;
}

#footer {
	animation: fade-simple 1s;
}

#level-big {
	animation: level-zoom-out 1s ease 1s, fade-simple 1s ease 0s;
	animation-fill-mode: forwards;
}

.play-caption-ani {
	opacity: 0;
	animation: fade-simple 1.5s;
	animation-delay: 1s;
	animation-fill-mode: forwards;
}

.square-container-ani {
	animation: fade-simple .5s;
	opacity: 0;
	animation-fill-mode: forwards;
}

.square-green {
	animation: fade-green 1s;
	animation-fill-mode: forwards;
}

.square-red {
	animation: fade-red 1s;
	animation-fill-mode: forwards;
}
