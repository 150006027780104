@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&display=swap');

body {
	font-family: 'Open Sans', sans-serif;
}

#header {
	display: flex;
	justify-content: center;
	padding-top: 60px;
	margin-bottom: 15px;
}

#header img {
	width: 55px;
	margin-right: 5px;
}

#header h1 {
	font-size: 40px;
	margin-left: 5px;
}

#header h1 span {
	font-weight: normal;
}

#header a {
	display: flex;
	color: #000;
	text-decoration: none;
}

.body-content {
	min-height: calc(100vh - 370px);
	padding-bottom: 80px;
}

#home-page {
	border: 3px solid #000;
	width: 450px;
	border-radius: 15px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

#home-page h3 {
	font-weight: 600;
	text-align: center;
	margin-top: 25px;
}

#home-page a {
	display: flex;
	justify-content: center;
	border: 3px solid #000;
	border-radius: 100px;
	width: 150px;
	padding: 5px;
	color: #000;
	text-decoration: none;
	font-weight: bold;
	font-size: 18px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
}

#home-page a img {
	width: 25px;
	margin-left: 10px;
}

.hovering-btn {
	animation-name: arrow-move;
	animation-duration: .2s;
	animation-fill-mode: forwards;
}

@keyframes arrow-move {
	from {
		transform: translateX(0px);
	}
	to {
		transform: translateX(10px);
	}
}

#footer {
	display: flex;
	justify-content: center;
	height: 100px;
	align-items: center;
}

#footer h3 {
	font-weight: 600;
}

#footer a {
	font-weight: bold;
	color: #000;
	text-decoration: none;
}

#footer h3 img {
	width: 25px;
	margin-bottom: -5px;
	margin-left: 3px;
	margin-right: 3px;
}

#play-page {
	border: 3px solid #000;
	width: 840px;
	border-radius: 15px;
	display: block;
	margin-left: auto;
	height: 334px;
	margin-right: auto;
}

.play-caption {
	font-weight: 600;
	text-align: center;
	margin-bottom: 0;
	margin-top: 25px;
}

.square-container {
	margin-left: 100px;
	margin-right: 100px;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: 35px;
}

.square {
	margin: 5px;
	background-color: #ececec;
}

.square-easy {
	width: 190px;
	height: 190px;
}

.square-medium {
	width: 90px;
	height: 90px;
}

.square-hard {
	width: 40px;
	height: 40px;
}

#level-big {
	font-size: 50px;
	font-weight: bold;
	margin-top: 70px;
}

.level-small {
	text-align: center;
	margin-top: 0;
	font-size: 13px;
	font-weight: 600;
}

.square-green {
	background-color: #5fd253;
}

.square-red {
	background-color: red;
}

:focus {
	outline: none;
}
::-moz-focus-inner {
	border: 0;
}

body {
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

#gameover-page {
	border: 3px solid #000;
	width: 450px;
	border-radius: 15px;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

#gameover-page h3 {
	font-weight: 600;
	text-align: center;
	margin-top: 25px;
}

.gameover-caption {
	text-align: center;
	font-size: 13px;
	margin-bottom: 20px;
}

#gameover-page a {
	display: flex;
	justify-content: center;
	border: 3px solid #000;
	border-radius: 100px;
	width: 150px;
	padding: 5px;
	color: #000;
	text-decoration: none;
	font-weight: bold;
	font-size: 18px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 25px;
}

#gameover-page a img {
	width: 25px;
	margin-left: 10px;
}

#difficulty-page {
	border: 3px solid #000;
	width: 450px;
	border-radius: 15px;
	display: block;
	margin-left: auto;
	margin-right: auto;
	padding-bottom: 15px;
}

#difficulty-page h3 {
	font-weight: 600;
	text-align: center;
	margin-top: 25px;
}

#difficulty-page a {
	display: flex;
	justify-content: center;
	border: 3px solid #000;
	border-radius: 100px;
	width: 150px;
	padding: 5px;
	color: #000;
	text-decoration: none;
	font-weight: bold;
	font-size: 18px;
	margin-left: auto;
	margin-right: auto;
	margin-bottom: 10px;
}

#difficulty-page a img {
	width: 25px;
	margin-left: 10px;
}
